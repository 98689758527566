<template>
  <!-- <div class="container-fluid" >
    <div class="row">
      <AppSlideTab />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
      </main>
    </div>
  </div> -->

  <div
    class="container-fluid"
    v-if="$route.meta.requiresAuth"
    @click="checkSessionTimeout"
  >
    <div class="row" v-if="this.basepathstatus == 1">
      <AppSlideTab />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
            <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{new Date().getFullYear()}} All rights reserved by edynamics Tech Solutions Pvt. Ltd.</div>
            <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
          </div>
      </main>
    </div>
    <div class="row" v-if="this.basepathstatus == 2">
      <AppVisitorTab />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
            <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{new Date().getFullYear()}} All rights reserved by edynamics Tech Solutions Pvt. Ltd.</div>
            <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
          </div>
      </main>
    </div>
    <div class="row" v-if="this.basepathstatus == 3">
      <AppCitizeTab />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
            <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{new Date().getFullYear()}} All rights reserved by edynamics Tech Solutions Pvt. Ltd.</div>
            <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
          </div>
      </main>
    </div>
    <div class="row" v-if="this.basepathstatus == 4">
      <AppAdminTab />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
            <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{new Date().getFullYear()}} All rights reserved by edynamics Tech Solutions Pvt. Ltd.</div>
            <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
          </div>
      </main>
    </div>
    <div class="row" v-if="this.basepathstatus == 5">
      <AppVisionTab />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
            <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{new Date().getFullYear()}} All rights reserved by edynamics Tech Solutions Pvt. Ltd.</div>
            <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
          </div>
      </main>
    </div>
  </div>
  <div class="container-fluid" v-if="!$route.meta.requiresAuth">
    <router-view />
  </div>
  
</template>
<script>
import AppSlideTab from "./components/SlideTabs.vue";
import AppSlideVisitorTab from "./components/SlideTabsVisitor.vue";
import AppSlideCitizenTab from "./components/SlideTabsCitizen.vue";
import AppSlideAdminTab from "./components/SlideTabsAdmin.vue";
import AppSlideVisionTab from "./components/SlideTabsVision.vue";
import Header from "./components/Header.vue"
export default {
  components: {
    AppSlideTab: AppSlideTab,
    AppVisitorTab: AppSlideVisitorTab,
    AppCitizeTab: AppSlideCitizenTab,
    AppAdminTab: AppSlideAdminTab,
    AppVisionTab:AppSlideVisionTab,
    Header: Header,
  },
  data() {
    return {
      baseUrl :window.location.origin,
      basepathstatus: 0,
    };
  },
  // || this.baseUrl == 'http://localhost:8080'
  mounted() {
    if (this.baseUrl == 'https://vc.nextedgecivictech.in') {
      this.basepathstatus = 2;
    } else if (this.baseUrl == 'https://demo.cm-connect.in'){
      this.basepathstatus = 3;
    } else if (this.baseUrl == 'https://test.nextedgecivictech.in'){
      this.basepathstatus = 4;
    } else if (this.baseUrl == 'https://vision.nextedgecivictech.in'){
      this.basepathstatus = 5;
    } else {
      this.basepathstatus = 1;
    }
  },
};
</script>
<style>
</style>