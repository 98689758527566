<template>
  <div
    class="
      layout-fixed-header-section
      d-lg-flex d-block
      justify-content-between
    "
  >
    <div class="header-label col-lg-9 col-12"></div>
    <div class="col-lg-3 col-12 text-end d-flex justify-content-end">
      <button
        type="button"
        class="btn position-relative d-flex align-items-center"
      >
        <i class="fas fa-bell custom-text-primary" aria-hidden="true"></i>
      </button>
      <div class="profile-vertical-devider"></div>
      <div class="profile-signout-dropdown-outer">
        <div class="dropdown">
          <button
            class="
              btn btn-secondary
              dropdown-toggle
              switch-branch-btn
              text-truncate
              d-inline-block
            "
            type="button"
            id="switchdealerbranch"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img v-if="this.basepathstatus == 5"
                  src="/assets/images/avatar-new.png"
                  class="profile-header-avatar rounded-circle"
                  alt=""
                  width="30"
                />
                <img v-else 
                  src="/assets/images/profile-10.jpg"
                  class="profile-header-avatar rounded-circle"
                  alt=""
                  width="30"
                />
              </div>
              <div v-if="this.basepathstatus == 5" class="flex-grow-1 ms-2">Vipul Patel</div>
              <div v-else class="flex-grow-1 ms-2">Kamalakar Patil</div>
            </div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="switchdealerbranch">
            <li v-if="this.basepathstatus != 5">
              <router-link class="dropdown-item" to="/myprofile"
                ><i class="fas fa-user me-2"></i>My Profile</router-link
              >
            </li>
            <li>
              <span class="dropdown-item cursor-pointer" @click="logout"><i class="fas fa-sign-out-alt me-2"></i>Sign Out</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.profile-header-avatar {
  background: #b3bae1 0% 0% no-repeat padding-box;
  padding: 2px;
}
</style>
<script>
export default {
  data() {
    return {
      baseUrl: window.location.origin,
      basepathstatus: 0,
    };
  },
  mounted() {
    if (this.baseUrl == 'https://vision.nextedgecivictech.in'){
      this.basepathstatus = 5;
    } else {
      this.basepathstatus = 1;
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location.href = "/";
    }
  }
}
</script>
