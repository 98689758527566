<template>
        <div class="text-center" v-if="rierarchyonemodalstatus">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 text-start">
              <button
                type="button"
                class="header-filter-btn-outer btn btn-light"
                @click="secondSlideModalOpen()"
              >
                <i
                  class="fa fa-arrow-left custom-text-primary"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="row justify-content-md-center">
                <div class="col-lg-3 col-md-3 col-12 card-width">
                  <div class="card text-center border-info mb-3">
                    <div class="card-body p-2">
                      <div class="mb-2 py-2">
                        <img
                          src="/assets/images/profile-4.jpg"
                          class="rounded-circle bg-light mx-auto d-block"
                          alt=""
                          width="50"
                        />
                      </div>
                      <p class="card-text mb-1">Mr. Suhas Vithal Desai</p>
                      <p class="card-sub-text mb-0">Resident</p>
                      <p class="card-sub-text mb-0">Nagala Park | 416012</p>
                      <p class="card-sub-text mb-0">Salaried (Menon Pistons)</p>
                    </div>
                    <div class="card-footer text-muted">Age : 42</div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-evenly pt-3">
                <div class="col-lg-3 col-md-3 col-12 card-width">
                  <div class="card text-center border-info mb-3">
                    <div class="card-body p-2">
                      <div class="mb-2 py-2">
                        <img
                          src="/assets/images/profile-5.jpg"
                          class="rounded-circle bg-light mx-auto d-block"
                          alt=""
                          width="50"
                        />
                      </div>
                      <p class="card-text mb-1">Mrs. Minal Suhas Desai</p>
                      <p class="card-sub-text mb-0">Resident</p>
                      <p class="card-sub-text mb-0">Nagala Park | 416012</p>
                      <p class="card-sub-text mb-0">House Wife</p>
                    </div>
                    <div class="card-footer text-muted">Age : 40</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-12 card-width">
                    <div class="card text-center border-info mb-3">
                      <div class="card-body p-2">
                        <div class="mb-2 py-2">
                          <img
                            src="/assets/images/profile-6.jpg"
                            class="rounded-circle bg-light mx-auto d-block"
                            alt=""
                            width="50"
                          />
                        </div>
                        <p class="card-text mb-1">Mr. Sunny Suhas Desai</p>
                        <p class="card-sub-text mb-0">Resident</p>
                        <p class="card-sub-text mb-0">Nagala Park | 416012</p>
                        <p class="card-sub-text mb-0">Student</p>
                      </div>
                      <div class="card-footer text-muted">Age : 21</div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-12 card-width">
                    <div class="card text-center border-info mb-3">
                      <div class="card-body p-2">
                        <div class="mb-2 py-2">
                          <img
                            src="/assets/images/profile-7.jpg"
                            class="rounded-circle bg-light mx-auto d-block"
                            alt=""
                            width="50"
                          />
                        </div>
                        <p class="card-text mb-1">Mr. Pramod Suhas Desai</p>
                        <p class="card-sub-text mb-0">Resident</p>
                        <p class="card-sub-text mb-0">Nagala Park | 416012</p>
                        <p class="card-sub-text mb-0">Student</p>
                      </div>
                      <div class="card-footer text-muted">Age : 19</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center pt-4" v-if="rierarchytwomodalstatus">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="row justify-content-md-center">
                <div class="col-lg-3 col-md-3 col-12 card-width">
                  <div class="card text-center border-info mb-3">
                    <div class="card-body p-2">
                      <button
                        type="button"
                        class="header-filter-btn-outer btn btn-light p-0"
                        disabled
                      >
                      <div class="mb-2 py-2">
                        <img
                          src="/assets/images/profile-1.jpg"
                          class="rounded-circle bg-light mx-auto d-block"
                          alt=""
                          width="50"
                        />
                      </div>
                      <p class="card-text mb-1">Mr. Vithal Manohar Desai</p>
                      <p class="card-sub-text mb-0">Resident</p>
                      <p class="card-sub-text mb-0">Ajara | 416505</p>
                      <p class="card-sub-text mb-0">Farmar</p>
                      </button>
                    </div>
                    <div class="card-footer text-muted">Age : 65</div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-evenly pt-3">
                <div class="col-lg-3 col-md-3 col-12 card-width">
                  <div class="card text-center border-info mb-3">
                    <div class="card-body p-2">
                      <button
                        type="button"
                        class="header-filter-btn-outer btn btn-light p-0"
                        disabled
                      >
                      <div class="mb-2 py-2">
                        <img
                          src="/assets/images/profile-2.jpg"
                          class="rounded-circle bg-light mx-auto d-block"
                          alt=""
                          width="50"
                        />
                      </div>
                      <p class="card-text mb-1">Mrs. Laxmi Vithal Desai</p>
                      <p class="card-sub-text mb-0">Resident</p>
                      <p class="card-sub-text mb-0">Ajara | 416505</p>
                      <p class="card-sub-text mb-0">House Wife</p>
                      </button>
                    </div>
                    <div class="card-footer text-muted">Age : 61</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-12 card-width">
                  <div class="card text-center border-info mb-3">
                    <div class="card-body p-2">
                      <button
                        type="button"
                        class="header-filter-btn-outer btn btn-light p-0"
                        disabled
                      >
                      <div class="mb-2 py-2">
                        <img
                          src="/assets/images/profile-3.jpg"
                          class="rounded-circle bg-light mx-auto d-block"
                          alt=""
                          width="50"
                        />
                      </div>
                      <p class="card-text mb-1">Mr. Abhijeet Vithal Desai</p>
                      <p class="card-sub-text mb-0">Non-Resident</p>
                      <p class="card-sub-text mb-0">Pune | 411045</p>
                      <p class="card-sub-text mb-0">Service (Tata Motors)</p>
                      </button>
                    </div>
                    <div class="card-footer text-muted">Age : 45</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-12 card-width">
                  <div class="card text-center border-info mb-3">
                    <div class="card-body p-2">
                      <button
                        type="button"
                        class="header-filter-btn-outer btn btn-light p-0"
                        @click="backhierarchymodalOpen()"
                      >
                        <div class="mb-2 py-2">
                          <img
                            src="/assets/images/profile-4.jpg"
                            class="rounded-circle bg-light mx-auto d-block"
                            alt=""
                            width="50"
                          />
                        </div>
                        <p class="card-text mb-1">Mr. Suhas Vithal Desai</p>
                        <p class="card-sub-text mb-0">Resident</p>
                        <p class="card-sub-text mb-0">Nagala Park | 416012</p>
                        <p class="card-sub-text mb-0">
                          Salaried (Menon Pistons)
                        </p>
                      </button>
                    </div>
                    <div class="card-footer text-muted">Age : 42</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  data() {
    return {
      rierarchyonemodalstatus: true,
      rierarchytwomodalstatus: false,
    };
  },
  methods: {
    backhierarchymodalOpen() {
        this.rierarchyonemodalstatus = true;
      this.rierarchytwomodalstatus = false;
    },
    secondSlideModalOpen() {
      this.rierarchyonemodalstatus = false;
      this.rierarchytwomodalstatus = true;
    },
  },
};
</script>
<style scoped>
.card-width {
  flex: 0 0 auto;
  width: 27%;
}
.card-footer {
  color: #6c757d;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
}
.card-text {
  color: #2b2e38;
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
}
.card-sub-text {
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  color: #6c757d;
    line-height: 16px;
}
.modal-body{
  padding: 10px 20px;
}
</style>
