<template>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="custom-form-group">
          <label for="formremarkInput" class="form-label"
            >Remark<span class="text-danger">*</span></label
          >
          <textarea
            type="text"
            v-model="remark"
            class="form-control"
            id="formremarkInput"
            placeholder="Enter Remark"
            autocomplete="off"
            rows="2"
            maxlength="250"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="custom-form-group text-center">
          <button type="button" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      remark: "",
    };
  },
};
</script>