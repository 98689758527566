<template>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <div class="custom-form-group">
                    <label for="formCurrentPasswordInput" class="form-label">
                      Current Password<span class="text-danger">*</span></label
                    >
                    <div class="input-group">
                      <input
                        class="form-control custom-input-passward"
                        placeholder="Enter Current Password"
                        aria-label="Password"
                        v-model="currentpassword"
                        :type="currentPasswordVisibility"
                        maxlength="12"
                        aria-describedby="formCurrentPasswordInput"
                        autocomplete="off"
                      />
                      <span class="input-group-text" id="formPasswordInput">
                        <button
                          @click="showCurrentPasswordType()"
                          v-if="currentPasswordVisibility == 'password'"
                          class="btn btn-link login-password-type"
                        >
                          <i class="far fa-eye-slash fa-sm"></i>
                        </button>
                        <button
                          @click="hideCurrentPasswordType()"
                          v-if="currentPasswordVisibility == 'text'"
                          class="btn btn-link login-password-type"
                        >
                          <i class="far fa-eye fa-sm"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.currentpassword.$error">
                      {{ v$.currentpassword.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-form-group">
                    <label for="formNewPasswordInput" class="form-label">
                      New Password<span class="text-danger">*</span></label
                    >
                    <div class="input-group">
                      <input
                        class="form-control custom-input-passward"
                        placeholder="Enter New Password"
                        aria-label="Password"
                        v-model="newpassword"
                        :type="newPasswordVisibility"
                        maxlength="12"
                        aria-describedby="formNewPasswordInput"
                        autocomplete="off"
                      />
                      <span class="input-group-text" id="formPasswordInput">
                        <button
                          @click="showNewPasswordType()"
                          v-if="newPasswordVisibility == 'password'"
                          class="btn btn-link login-password-type"
                        >
                          <i class="far fa-eye-slash fa-sm"></i>
                        </button>
                        <button
                          @click="hideNewPasswordType()"
                          v-if="newPasswordVisibility == 'text'"
                          class="btn btn-link login-password-type"
                        >
                          <i class="far fa-eye fa-sm"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.newpassword.$error">
                      {{ v$.newpassword.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-form-group">
                    <label for="formConfirmPasswordInput" class="form-label">
                      Confirm New Password<span class="text-danger"
                        >*</span
                      ></label
                    >
                    <div class="input-group">
                      <input
                        class="form-control custom-input-passward"
                        placeholder="Confirm Password"
                        aria-label="Password"
                        v-model="confirmpassword"
                        :type="confirmPasswordVisibility"
                        maxlength="12"
                        aria-describedby="formConfirmPasswordInput"
                        autocomplete="off"
                      />
                      <span class="input-group-text" id="formPasswordInput">
                        <button
                          @click="showConfirmPasswordType()"
                          v-if="confirmPasswordVisibility == 'password'"
                          class="btn btn-link login-password-type"
                        >
                          <i class="far fa-eye-slash fa-sm"></i>
                        </button>
                        <button
                          @click="hideConfirmPasswordType()"
                          v-if="confirmPasswordVisibility == 'text'"
                          class="btn btn-link login-password-type"
                        >
                          <i class="far fa-eye fa-sm"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.confirmpassword.$error">
                      {{ v$.confirmpassword.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    type="button"
                    class="btn reset-password-submit-btn"
                    @click="resetpasswordsubmit()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      
</template>
<script>
import { required, helpers, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default {
  data() {
    return {
      v$: useValidate(),
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
      currentPasswordVisibility: "password",
      newPasswordVisibility: "password",
      confirmPasswordVisibility: "password",
    };
  },
  watch: {
    currentpassword() {
      this.resetpasswordsubmit();
    },
    newpassword() {
      this.resetpasswordsubmit();
    },
    confirmpassword() {
      this.resetpasswordsubmit();
    },
  },
  validations() {
    return {
      currentpassword: {
        required: helpers.withMessage("Please enter current password", required),
        minLength: helpers.withMessage(
          "Password should be min 6 length",
          minLength(6)
        ),
      },
      newpassword: {
        required: helpers.withMessage("Please enter new password", required),
        minLength: helpers.withMessage(
          "Password should be min 6 length",
          minLength(6)
        ),
      },
      confirmpassword: {
        required: helpers.withMessage("Please enter confirm password", required),
        minLength: helpers.withMessage(
          "Password should be min 6 length",
          minLength(6)
        ),
      },
    };
  },

  methods: {
    resetpasswordsubmit() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        alert("password successfully updated.");
      } else {
        this.$error;
      }
    },
    showCurrentPasswordType() {
      this.currentPasswordVisibility = "text";
    },
    hideCurrentPasswordType() {
      this.currentPasswordVisibility = "password";
    },
    showNewPasswordType() {
      this.newPasswordVisibility = "text";
    },
    hideNewPasswordType() {
      this.newPasswordVisibility = "password";
    },
    showConfirmPasswordType() {
      this.confirmPasswordVisibility = "text";
    },
    hideConfirmPasswordType() {
      this.confirmPasswordVisibility = "password";
    },
  },
};
</script>
<style scoped>
.reset-password-submit-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 19px;
  font-family: "AcuminPro-Regular";
  margin-right: 12px;
}
.reset-password-submit-btn:focus {
  box-shadow: none;
}
</style>