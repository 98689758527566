<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-fixed-outer">
        <header class="navbar navbar-dark bg-dark flex-md-nowrap">
          <div class="navbar-brand me-0">
            <div class="navbar-brand-image ">
              <img src="/assets/images/police-logo.png" class="rounded-circle dashboard-img-border" alt="client-logo" width="40" height="40" />
            </div>
            <div class="navbar-brand-label text-capitalize">Goa Police</div>
          </div>
          <button class="navbar-toggler d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </header>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <ul class="nav flex-column" id="accordionNavLinkParent">
            <li class="nav-item">
              <router-link class="nav-link" to="/dashboard"><img src="/assets/images/icon/overview-tab-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Dashboard</router-link>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-settings-collapse" aria-expanded="false">
                <span class="mr-auto"><img src="/assets/images/icon/setting-tab-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Settings</span>
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-settings-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">API</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">DLT</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Templates</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Offices</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-institutions-collapse" aria-expanded="false">
                <img src="/assets/images/icon/voters-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Guest
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-institutions-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/voters/new">New</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/visitor/total">Total</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/voters/campaign">Campaign</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/searchvoter">Search Guest</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-voters-collapse" aria-expanded="false">
                <img src="/assets/images/icon/voters-tab-icon.svg" class="navlink-tab-icon" alt=""
                  width="16" />Institutions
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-voters-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/institution/new">Add Institution</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/institution/bank">Young Co-Op Bank</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/institution/mill">Shahu Sugar Mill</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/institution/collage">ABC Collage</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-voters-service-collapse" aria-expanded="false">
                <img src="/assets/images/icon/voters-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Guest
                Services
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-voters-service-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">Ambulance</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Aadhaar</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">PAN</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/whatsapplogs"><img src="/assets/images/icon/feedback-tab-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />WhatsApp Logs</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/createcampaign"><img src="/assets/images/icon/feedback-tab-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Campaign Template</router-link>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-citizen-collapse" aria-expanded="false">
                <img src="/assets/images/icon/complaint-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Guest
                Complaints
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-citizen-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">New</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Todays Followups</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Pending Followups</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">On Hold</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Total Open</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Resolved</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Cancelled</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Feedback</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-upcoming-users-collapse" aria-expanded="false">
                <img src="/assets/images/icon/customers-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Users
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-upcoming-users-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">New</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">List Users</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-upcoming-karyakartas-collapse" aria-expanded="false">
                <img src="/assets/images/icon/users-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Karyakartas
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-upcoming-karyakartas-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/karyakartas/addnew">New</router-link>
                  </li>
                  <!-- <li>
                    <router-link class="nav-link" to="/karyakartas/list">List Karyakartas</router-link>
                  </li> -->
                  <li>
                    <router-link class="nav-link" to="/karyakartas/list">List Karyakartas</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-system-visitor-collapse" aria-expanded="false">
                <img src="/assets/images/icon/visitors-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Guests
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-visitor-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">New</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Visits Done</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Visits Cancelled</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-system-appointment-collapse" aria-expanded="false">
                <img src="/assets/images/icon/appointment-tab-icon.svg" class="navlink-tab-icon" alt=""
                  width="16" />Appointments
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-appointment-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">New</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Todays</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Upcoming</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">completed</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Cancelled</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-system-communications-collapse" aria-expanded="false">
                <img src="/assets/images/icon/feedback-tab-icon.svg" class="navlink-tab-icon" alt=""
                  width="16" />Communications
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-communications-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">SMS</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">WhatsApp</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Email</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-system-phone-collapse" aria-expanded="false">
                <img src="/assets/images/icon/phone-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Phone
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-phone-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">Virtual Numbers</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">User Extetions</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-system-billing-collapse" aria-expanded="false">
                <img src="/assets/images/icon/billing-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Billing
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-billing-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">Dashboard</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Tax Invoices</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Account Statement</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-support-collapse" aria-expanded="false">
                <img src="/assets/images/icon/support-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Support
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-support-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">Create Ticket</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Open Tickets</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Closed Tickets</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-logs-collapse" aria-expanded="false">
                <img src="/assets/images/icon/logs-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Logs
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-logs-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link class="nav-link" to="/underprogress">SMS Logs</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">WhatsApp Logs</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Email Logs</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Incoming Logs</router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/underprogress">Outgoing Logs</router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-inner">
          <div class="pro-dms-logo-outer">
            <img src="/assets/images/visitor-logo-footer.png" class="img-fluid" alt="" width="200" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      permission_fks: [],
      userbranch: { label: "Pandit Automotive Pune", value: "1" },
      userbranchlist: [
        { label: "Pandit Automotive Pune", value: "1" },
        { label: "Pandit Automotive Satara", value: "2" },
        { label: "Pandit Automotive Kolhapur", value: "3" },
      ],
      client_logo: "",
      client_owner_img: "",
      client_name: "",
    };
  },
  mounted() {
    if (localStorage.client_user_details) {
      var userData = JSON.parse(localStorage.client_user_details);
      var permission_fks = userData.mb28;
      if (permission_fks) {
        this.permission_fks = permission_fks.split(",");
      } else {
        this.permission_fks = [];
      }
    }
    this.client_info = JSON.parse(localStorage.client_info);
    this.client_logo = "https://storage.googleapis.com/mera-neta-master/"+this.client_info.maa50;
    this.client_owner_img = "https://storage.googleapis.com/mera-neta-master/"+this.client_info.maa51;
    this.client_name = this.client_info.maa2;
  },
};
</script>

