import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config' 
import VueToast from 'vue-toast-notification';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../public/assets/css/style.css';
import '../public/assets/css/fonts.css';
import '../public/assets/css/sidebars.css';
import '../public/assets/css/login.css';
import '../public/assets/css/customform.css';
import '../public/assets/css/calendar.css';
import '../public/assets/css/datatable.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import Multiselect from '@vueform/multiselect';
import FullCalendar from './fullcalendar';
import Datepicker from 'vue3-datepicker';
import '@vueform/multiselect/themes/default.css';
import global from "@/global";

import ResetPasswordModal from "./components/modal/ResetPasswordModal.vue"
import OrganizationChart from "./components/modal/OrganizationChart.vue"
import AddFollowupModal from "./components/modal/AddFollowupsModal.vue"
import EngagementTotalVoterModal from "./components/modal/EngagementTotalVoterModal.vue"
import MaintenerTotalVoterModal from "./components/modal/MaintenerTotalVoterModal.vue"
import SubOrganizationChart from "./components/modal/SubOrganizationChart.vue"
import EditDrivingDetailModalTabs from "./pages/voterstabs/EditDrivingDetailModalTabs.vue"
import VoterSchemeDetailModalTabs from "./pages/voterstabs/VoterSchemeDetailModalTabs.vue"
import VoterHistoryDetailsModalaTabs from "./pages/voterstabs/VoterHistoryDetailsModalaTabs.vue"


import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Chart from 'primevue/chart';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import 'primeicons/primeicons.css';
import VueNumberFormat from 'vue-number-format';
import StarRating from 'vue-star-rating';

// const app = createApp(App)
const app = createApp({
    render () { return h(App); },
    provide: { global },
});
app.component('StarRating', StarRating)
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("ResetPasswordModal", ResetPasswordModal);
app.component("AddFollowupModal", AddFollowupModal);
app.component("Multiselect", Multiselect);
app.component('FullCalendar', FullCalendar);
app.component('Datepicker', Datepicker);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('Chart', Chart);
app.component('DataTable', DataTable);
app.component('Button', Button);
app.component('Column', Column);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox);
app.component('InputSwitch', InputSwitch);
app.component('Calendar', Calendar);
app.component('Divider', Divider);
app.component('FileUpload', FileUpload);
app.component('ProgressBar', ProgressBar);
app.component('OrganizationChart', OrganizationChart);
app.component('EngagementTotalVoterModal', EngagementTotalVoterModal);
app.component('MaintenerTotalVoterModal', MaintenerTotalVoterModal);
app.component('SubOrganizationChart', SubOrganizationChart);
app.component('EditDrivingDetailModalTabs', EditDrivingDetailModalTabs);
app.component('VoterSchemeDetailModalTabs', VoterSchemeDetailModalTabs);
app.component('VoterHistoryDetailsModalaTabs', VoterHistoryDetailsModalaTabs);

app.use(router)
app.use(store)
app.use(PrimeVue)
app.use(VueNumberFormat)
app.use(VueToast)
app.mount('#app')
app.config.devtools = true;

