import { createRouter, createWebHistory } from 'vue-router';
const routes = [

  {
    path: '/',
    name: 'Home',
    exact: true,
    component: () =>
      import('./pages/AccessCode.vue'),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/accesscode',
    name: 'AccessCode',
    exact: true,
    component: () =>
      import('./pages/AccessCode.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import('./pages/Dashboard.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/karyakartas/addnew',
    name: 'NewKaryakartasForm',
    exact: true,
    component: () =>
      import('./pages/karyakartas/NewKaryakartasForm.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/karyakartas/list',
    name: 'KaryakartasList',
    component: () =>
      import('./pages/karyakartas/KaryakartasList.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/karyakartas/report',
    name: 'KaryakartasReports',
    component: () =>
      import('./pages/karyakartas/KaryakartasReports.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  
  {
    path: '/voters/new',
    name: 'NewVoters',
    component: () =>
      import('./pages/voters/New.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/searchvoter',
    name: 'SearchVoter',
    component: () =>
      import('./pages/voters/SearchVoter.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  
  {
    path: '/voters/total',
    name: 'VoterTotal',
    component: () =>
      import('./pages/TotalVoters.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/voters/report',
    name: 'TotalReport',
    component: () =>
      import('./pages/TotalVoterReport.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/voters/campaign',
    name: 'VoterCampaign',
    component: () =>
      import('./pages/CampaignList.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/voters/campaign/logs/:campaignId?',
    name: 'VoterCampaignLogs',
    component: () =>
      import('./pages/campaigntabs/CampaignLogs.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/voters/campaign/report/:campaignId?',
    name: 'VoterCampaignReports',
    component: () =>
      import('./pages/campaigntabs/CampaignReports.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/voters/static',
    name: 'StaticVoters',
    component: () =>
      import('./pages/voters/StaticVoters.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/institution/new',
    name: 'CreateInstitution',
    component: () =>
      import('./pages/institutiontabs/CreateInstitution.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/institution/bank',
    name: 'InstitutionBank',
    component: () =>
      import('./pages/institutiontabs/InstitutionBank.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/institution/mill',
    name: 'InstitutionMills',
    component: () =>
      import('./pages/institutiontabs/InstitutionMills.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/institution/collage',
    name: 'InstitutionCollage',
    component: () =>
      import('./pages/institutiontabs/InstitutionCollage.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/complaints/totalopen',
    name: 'Customers',
    component: () =>
      import('./pages/Customers.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/myprofile',
    name: 'MyProfile',
    component: () =>
      import('./pages/MyProfile.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/whatsapplogs',
    name: 'WhatsAppLogs',
    component: () =>
      import('./pages/WhatsAppLogs.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/createcampaign',
    name: 'CreateCampaign',
    component: () =>
      import('./pages/CreateCampaign.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () =>
      import('./pages/NotFound'),
      meta: {
        requiresAuth: false,
      },
  },
  {
    path: '/underprogress',
    name: 'underprogress',
    exact: true,
    component: () =>
      import('./pages/UnderProgress.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/visitor/total',
    name: 'VisitorTotal',
    component: () =>
      import('./pages/TotalVisitor.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/visitor/totallist',
    name: 'VisitorList',
    component: () =>
      import('./pages/TotalVisitorList.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/citizen/total',
    name: 'CitizenTotal',
    component: () =>
      import('./pages/TotalCitizen.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/citizen/death',
    name: 'DeathsTotal',
    component: () =>
      import('./pages/DeathsTotal.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/citizen/report',
    name: 'CitizenTotalReport',
    component: () =>
      import('./pages/TotalCitizenReport.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/schemes/total',
    name: 'SchemeList',
    component: () =>
      import('./pages/scheme/SchemeList.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/schemes/report',
    name: 'CitizenSchemeList',
    component: () =>
      import('./pages/scheme/CitizenSchemeList.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/schemes/beneficiaries',
    name: 'BeneficiariesCitizen',
    component: () =>
      import('./pages/scheme/BeneficiariesCitizen.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/schemes/discontinued',
    name: 'DiscontinuedScheme',
    component: () =>
      import('./pages/scheme/DiscontinuedScheme.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/announcement/new',
    name: 'NewAnnouncement',
    component: () =>
      import('./pages/announcement/NewAnnouncement.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/announcement/previous',
    name: 'PreviousAnnouncements',
    component: () =>
      import('./pages/announcement/PreviousAnnouncements.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/poll/new',
    name: 'NewPoll',
    component: () =>
      import('./pages/polls/NewPoll.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/poll/totalpoll',
    name: 'PollsList',
    component: () =>
      import('./pages/polls/PollsList.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/poll/logs',
    name: 'PollLogs',
    component: () =>
      import('./pages/polls/PollLogs.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/poll/reports',
    name: 'PollReports',
    component: () =>
      import('./pages/polls/PollReports.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/religion',
    name: 'Religion',
    component: () =>
      import('./pages/listingparliament/Religion.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/categories',
    name: 'Categories',
    component: () =>
      import('./pages/listingparliament/Categories.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/caste',
    name: 'Caste',
    component: () =>
      import('./pages/listingparliament/Caste.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/subcaste',
    name: 'SubCaste',
    component: () =>
      import('./pages/listingparliament/SubCaste.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/districts',
    name: 'Districts',
    component: () =>
      import('./pages/listingparliament/Districts.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/talukas',
    name: 'Talukas',
    component: () =>
      import('./pages/listingparliament/Talukas.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/zillaparishads',
    name: 'ZillaParishads',
    component: () =>
      import('./pages/listingparliament/ZillaParishads.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/panchayatsamities',
    name: 'PanchayatSamities',
    component: () =>
      import('./pages/listingparliament/PanchayatSamities.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/grampanchayats',
    name: 'GramPanchayats',
    component: () =>
      import('./pages/listingparliament/GramPanchayats.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/nagarparishad',
    name: 'NagarParishad',
    component: () =>
      import('./pages/listingparliament/NagarParishad.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/nagarpalika',
    name: 'NagarPalika',
    component: () =>
      import('./pages/listingparliament/NagarPalika.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/mahanagarpalika',
    name: 'MahanagarPalika',
    component: () =>
      import('./pages/listingparliament/MahanagarPalika.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/mpsloksabha',
    name: 'MPsLokSabha',
    component: () =>
      import('./pages/listingparliament/MPsLokSabha.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/mpsrajyasabha',
    name: 'MPsRajyaSabha',
    component: () =>
      import('./pages/listingparliament/MPsRajyaSabha.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/mlasvidhansabha',
    name: 'MLAsVidhanSabha',
    component: () =>
      import('./pages/listingparliament/MLAsVidhanSabha.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/mlasvidhanparishad',
    name: 'MLAsVidhanParishad',
    component: () =>
      import('./pages/listingparliament/MLAsVidhanParishad.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/collectors',
    name: 'Collectors',
    component: () =>
      import('./pages/listingparliament/Collectors.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/municipalcommissioners',
    name: 'MunicipalCommissioners',
    component: () =>
      import('./pages/listingparliament/MunicipalCommissioners.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/zpceos',
    name: 'ZPCEOs',
    component: () =>
      import('./pages/listingparliament/ZPCEOs.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/policecommissioners',
    name: 'PoliceCommissioners',
    component: () =>
      import('./pages/listingparliament/PoliceCommissioners.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing/mayors',
    name: 'Mayors',
    component: () =>
      import('./pages/listingparliament/Mayors.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/individual',
    name: 'IndividualData',
    component: () =>
      import('./components/mobileinfotabs/IndividualData.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/mobileinfo',
    name: 'MobileInfo',
    component: () =>
      import('./components/mobileinfotabs/MobileInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/allrecords',
    name: 'MobileInfo',
    component: () =>
      import('./components/mobileinfotabs/Allrecords.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/creditscore',
    name: 'CreditScore',
    component: () =>
      import('./components/mobileinfotabs/CreditScore.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/mobilefetchinfo',
    name: 'MobileAdminInfo',
    component: () =>
      import('./components/mobileadmininfo/MobileAdminInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/uaninfo',
    name: 'UANAdminInfo',
    component: () =>
      import('./components/mobileinfotabs/UANInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/dininfo',
    name: 'DINAdminInfo',
    component: () =>
      import('./components/mobileinfotabs/DINInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/pantodininfo',
    name: 'PanDINAdminInfo',
    component: () =>
      import('./components/mobileinfotabs/PanToDINInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/gstinfo',
    name: 'GSTAdminInfo',
    component: () =>
      import('./components/mobileinfotabs/GSTInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/courtcase',
    name: 'CourtCaseAdminInfo',
    component: () =>
      import('./components/mobileinfotabs/CourtCaseInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/masterinfo',
    name: 'MasterDateAdminInfo',
    component: () =>
      import('./components/mobileinfotabs/MasterDateInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/livecaller',
    name: 'LiveCallerAdminInfo',
    component: () =>
      import('./components/mobileinfotabs/LiveCallerInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/faceinfo',
    name: 'FaceInfo',
    component: () =>
      import('./components/mobileinfotabs/FaceInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/rtoinfo',
    name: 'RtoInfo',
    component: () =>
      import('./components/mobileinfotabs/RtoInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/drivinginfo',
    name: 'DrivingInfo',
    component: () =>
      import('./components/mobileinfotabs/DrivingInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/fastaginfo',
    name: 'FastagInfo',
    component: () =>
      import('./components/mobileinfotabs/FastagInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/paninfo',
    name: 'PanInfo',
    component: () =>
      import('./components/mobileinfotabs/PanInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/doctorverificationinfo',
    name: 'DoctorVerificationInfo',
    component: () =>
      import('./components/mobileinfotabs/DoctorVerificationInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/fetchdata/incometaxinfo',
    name: 'IncomeTaxInfo',
    component: () =>
      import('./components/mobileinfotabs/IncomeTaxInfo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/billing/accountstatement',
    name: 'AccountStatement',
    component: () =>
      import('./pages/billing/AccountStatement.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listing',
    name: 'VisionListing',
    component: () =>
      import('./pages/VisionListing.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/listings/:mobileId?',
    name: 'MobResDetails',
    component: () =>
      import('./pages/MobileResponseView.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () =>
      import('./pages/Demo.vue'),
      meta: {
        requiresAuth: true,
      },
  },
  
]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes
})

router.beforeEach((to, from, next) => {
  const authenticatedUser = localStorage.user_reference;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const checkModuleAccess = to.matched.some(record => record.meta.checkModuleAccess);
  const moduleId = to.meta.moduleId;
  const permission_fks = [1, 11, 12];

  if (requiresAuth && !authenticatedUser) {
      next('/');
  }
  else {
      if (checkModuleAccess) {
          if (permission_fks.includes(moduleId)) {
              next();
          } else {
              next(false);
          }
      } else {
          next();
      }

  }

});

export default router