import { createStore } from "vuex";

export default createStore({
  state: {
    count: 1,
    //popupstatus:false,
    clientUser:{clientusermodals:false,editClientUserData:{},clientUserBtnStatus:1,userBranchList:[]}, 
    driver:{driverModal:false,editDriverData:{},driverBtnStatus:1},   
  },
  mutations: {
    // increment(state,status) { 
    //   state.popupstatus = status;
    // },
    clientUserModalStatus(state,status)
    {
      state.clientUser.clientusermodals = status;      
    },
    addNewClientUser(state,data)
    {
      state.clientUser.editClientUserData = '';    
      state.clientUser.clientUserBtnStatus = data; 
    },
    clientUserInfo(state,data)
    {
      state.clientUser.editClientUserData = data;    
      state.clientUser.clientUserBtnStatus = data.btnstatus; 
    },
    getBranchLocations(state,data)
    {
      state.clientUser.userBranchList = data;
    },
    //Driver
    driverModalStatus(state,status)
    {
      state.driver.driverModal = status;
    },
    getDriverInfo(state,data)
    {
      state.driver.editDriverData = data;    
      state.driver.driverBtnStatus = data.btnstatus; 
    },
    addNewDriver(state,data)
    {
      state.driver.editDriverData = '';    
      state.driver.driverBtnStatus = data; 
    }

    
  },
  actions: {
    increment(context,status) {
      context.commit("increment",status);
    },
    //Client User
    clientUserModalStatus(context,status)
    {
      context.commit("clientUserModalStatus",status);
    },
    addNewClientUser(context,data)
    {
      context.commit("addNewClientUser",data);
    },
    clientUserInfo(context,data)
    {
      context.commit("clientUserInfo",data);
    },
    getBranchLocations(context,data)
    {
      context.commit("getBranchLocations",data);
    }, 
    //Driver 
    driverModalStatus(context,status)
    {
      context.commit("driverModalStatus",status);
    },
    getDriverInfo(context,data)
    {
      context.commit("getDriverInfo",data);
    },
    addNewDriver(context,data)
    {
      context.commit("addNewDriver",data);
    }
  } 
});
