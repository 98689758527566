import axios from "axios";
// axios.defaults.baseURL = "https://edynamicsadmin.in";

export default class ApiService {
    StorageObjUserRef = localStorage.user_reference;
    StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
    client = this.StorageObjClient.maa1 ? this.StorageObjClient.maa1 : '';

    HeaderParam = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "content-type": "application/json",
        "Accept": "application/json"
    };

    AuthenticatedUserHeader = {
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': "Bearer " + this.StorageObjUserRef,
        'client': this.client,
    };

    // HeaderParam = {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //     "content-type": "application/json",
    //     "Accept": "application/json"
    // };


    commonapiall = (url, params, method, type, item) => {
        return axios(url, {
            method: method,
            headers: params,
            data: item
        })
            .then(response => {
                if (response.data.status === 403) {
                    // if (type === 1) {
                    //   logout();
                    // }
                } else {
                    return response.data;
                }
            })
            .catch(error => {
                throw error;
            });
    };
    clientdetail(item) {
        let URL = "/api/";
        return this.commonapiall(URL, this.HeaderParam, "Get", 0, item);
    }

    checknetaaccesscode(item) {
        let URL = "/api/checknetaaccesscode";
        return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
    }

    getmeranetawhatsappmsgs(item) {
        let URL = "/api/getmeranetawhatsappmsgs";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getlicensedetails(item) {
        let URL = "/api/getlicensedetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getvoterdetails(item) {
        let URL = "/api/getvoterdetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getsecvoterdetails(item) {
        let URL = "/api/getsecvoterdetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    setprimaryvoterid(item) {
        let URL = "/api/setprimaryvoterid";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getaadhardetails(item) {
        let URL = "/api/getaadhardetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getpandetails(item) {
        let URL = "/api/getpandetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getmeranetagenderdetails(item) {
        let URL = "/api/getmeranetagenderdetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getmeranetaprofessiondetails(item) {
        let URL = "/api/getmeranetaprofessiondetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getmeranetabloodgroupdetails(item) {
        let URL = "/api/getmeranetabloodgroupdetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    generateaadharotp(item) {
        let URL = "/api/generateaadharotp";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getaadharcarddetails(item) {
        let URL = "/api/getaadharcarddetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getvoteriddetails(item) {
        let URL = "/api/getvoteriddetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    addvoter(item) {
        let URL = "/api/addvoter";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    triggercalltomeraneta(item) {
        let URL = "/api/triggercalltomeraneta";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getcampaign(item) {
        let URL = "/api/getcampaign";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getcampaignlogs(item) {
        let URL = "/api/getcampaignlogs";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    campaignreport(item) {
        let URL = "/api/campaignreport";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    savecampaign(item) {
        let URL = "/api/savecampaign";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    campaigntemplates(item) {
        let URL = "/api/campaigntemplates";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "get", 0, item);
    }

    bulkcall(item) {
        let URL = "/api/bulkcall";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    importvoters(item) {
        let URL = "/api/importvoters";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getwhatsapplogs(item) {
        let URL = "/api/getwhatsapplogs";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getwhatsappchatlogs(item) {
        let URL = "/api/getwhatsappchatlogs";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    storecampaigntemplate(item) {
        let URL = "/api/storecampaigntemplate";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getcampaigntemplates(item) {
        let URL = "/api/getcampaigntemplates";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    getkaryakartasreport(item) {
        let URL = "/api/getkaryakartasreport";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }

    sendwhatsappmessage(item) {
        let URL = "/api/sendwhatsappmessage";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    submitvoterdata(item) {
        let URL = "/api/submitvoterdata";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getstates(item) {
        let URL = "/api/getstates";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getdistricts(item) {
        let URL = "/api/getdistricts";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    generateOTPforgetmobiledetails(item) {
        let URL = "/api/generateOTPforgetmobiledetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getmobiledetails(item) {
        let URL = "/api/getmobiledetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    checkmobilenumberandgetdetails(item) {
        let URL = "/api/checkmobilenumberandgetdetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getRCdetails(item) {
        let URL = "/api/getRCdetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getDLDetails(item) {
        let URL = "/api/getDLDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getPANcardDetails(item) {
        let URL = "/api/getPANcardDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getfasTagDetails(item) {
        let URL = "/api/getfasTagDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getPrefillDetails(item) {
        let URL = "/api/getPrefillDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getFaceMatchDetails(item) {
        let URL = "/api/getFaceMatchDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    deleteprefilldata(item) {
        let URL = "/api/deleteprefilldata";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getMobileToPanDetails(item) {
        let URL = "/api/getMobileToPanDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getMobileToNameDetails(item) {
        let URL = "/api/getMobileToNameDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getMobileToUPIDetails(item) {
        let URL = "/api/getMobileToUPIDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getDINDetails(item) {
        let URL = "/api/getDINDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getUANHistoryDetails(item) {
        let URL = "/api/getUANHistoryDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getMobileToUANDetails(item) {
        let URL = "/api/getMobileToUANDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getGSTDetails(item) {
        let URL = "/api/getGSTDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getCourtDetails(item) {
        let URL = "/api/getCourtDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getMobileMasterDetails(item) {
        let URL = "/api/getMobileMasterDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getMobileMasterDetailsWithCreditScore(item) {
        let URL = "/api/getMobileMasterDetailsWithCreditScore";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    checkliveCall(item) {
        let URL = "/api/checkliveCall";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "get", 0, item);
    }
    getcriminalrecords(item) {
        let URL = "/api/getcriminalrecords";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    }
    conferencecall(item) {
        let URL = "/api/conferencecall";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    }
    getaccountStatement(item) {
        let URL = "/api/getaccountStatement";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    } 
    getcreditscore(item) {
        let URL = "/api/getcreditscore";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    } 
    getlisting(item) {
        let URL = "/api/getlisting";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    } 
    getmobileresponse(item) {
        let URL = "/api/getmobileresponse";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    }
    getPanToDINDetails(item) {
        let URL = "/api/getPanToDINDetails";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    }
    allrecordspdf(item) {
        let URL = "/api/allrecordspdf";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    }
}

