import { reactive } from "vue";

const state = reactive({
   lk3: '',
   personalInfo: {
      title: { value: 1, label: 'Mr.' },
      firstname: '',
      lastname: '',
      middlename: '',
      dateofbirth: '',
      dateofmarriage: '',
      gender: '',
      bloodgroup: '',
      userEditDialog: false,
      userid: ''
   },
   showLoader: false,
   loading1: false,
   userListData: '',
   totalRecords: 0,
   contactInfo: {
      primarycountrycode: { code: 101, name: '+91', ao4: 91 },
      primarymobileno: '',
      primaryemail: '',
      secondarycountrycode: { code: 101, name: '+91', ao4: 91 },
      secondarymobileno: '',
      secondaryemail: '',
      familymobileno: '',
      familyrelation: '',
      correspondencecountry: { value: 101, label: 'India' },
      correspondencestate: '',
      correspondencecity: '',
      correspondencearea: '',
      correspondencepincode: '',
      permanantcountry: { value: 101, label: 'India' },
      permanantstate: '',
      permanantcity: '',
      permanantarea: '',
      permanantpincode: ''
   },
   educationInfo: {
      education: '',
      edudetails: '',
      employeephoto_fullurl: '',
      employeeid_fullurl: ''
   },
   jobOfferDetails: {
      department: '',
      designation: '',
      bd56: '',
      bd57: '',
      bd58: '',
      bd59: '',
   },
   userStatusDetails: {
      bd20: '',
      bd30: '',
      bd33: '',
   },
   userRoleManualPermission: {
      bd22: '',
      role: '',
      bd24: '',
   },
   userOtherPermission: {
      teamlead: '',
      mobile_permission: false,
      email_permission: false,
      password_permission: false,
      delete_permission: false,
      bm15: '',
   },
   toastStatus: 0,
   ca1: '',
   createdEmpId: '',
   createdEmpName: '',
   createdEmpBrandId: '',
   employeeInfo: '',
   jobPostPopup: false,
   PopupEmployerFk: '',
   PopupEmployerFkValue: '',
   PopupEmployerDealershipType: '',
   PopupEmployerCity: '',
   userlist_page_no: ''
});

export default { state };